'use client'

import React, { ReactNode, useEffect, useRef, useState } from "react"
import { closeAlldropdowns } from "@/services/theme"
import { toggleArrayElement } from "@/services/utils"

type ComplexOption = {label: string, sub: string}

type DropdownProps = {
    title?: string,
    subTitle?: string,
    value: string | string[],
    multiple?: any | boolean,
    search?: boolean,
    nullable?: boolean,
    options: Record<string, string|ComplexOption>,
    placeholder?: string | ReactNode,
    onChange: Function,
    className?: string
    withLabel?: boolean
}

export default function Dropdown({
    title,
    subTitle,
    value,
    multiple = false,
    search = false,
    nullable = true,
    options = {},
    placeholder = 'Tous',
    onChange,
    className = '',
    withLabel = false
}: DropdownProps) {

    const ref = useRef<HTMLDivElement>(null)
    const refButton = useRef<HTMLButtonElement>(null)
    const refTitle = useRef<HTMLElement>(null)
    const refDropdown = useRef<HTMLDivElement>(null)

    const multipleMaxDisplayedItems = 3
    const multipleOverMaxDisplayedItemsLabel = " items"

    const [optionSearch, setOptionSearch] = useState("")

    useEffect(() => {
        if (multiple === false) {
            if (Array.isArray(value) === true) {
                onChange("")
            }
            return
        }
        if (Array.isArray(value) === false) {
            onChange([])
        }
    }, [])

    return (
        <>
            {title && multiple === false &&
                <>
                    <h4 className="text-15 fw-500 ls-2 lh-16">
                        {title}
                    </h4>
                </>
            }
            <div ref={ref} className={"select" + (multiple === false ? " js-select" : " js-multiple-select") + (withLabel ? " libelle" : "") + " " + (className)}>
                {title && multiple &&
                    <h4 className="text-15 fw-500 ls-2 lh-16">
                        {title}
                    </h4>
                }
                <button ref={refButton} className="select__button js-button" onClick={() => {
                    if (refDropdown.current.classList.contains("-is-visible")) {
                        refDropdown.current.classList.remove("-is-visible");
                    } else {
                        closeAlldropdowns();
                        refDropdown.current.classList.add("-is-visible");
                    }

                    if (search === true) {
                        (ref.current.querySelector(".js-search") as HTMLInputElement).focus();
                    }
                }}>
                    {subTitle && <label htmlFor="floatingSelect">{subTitle}</label>}
                    <span ref={refTitle} className="js-button-title">{value && value.length > 0 ? (multiple === false ? (typeof options[(value as string | number)] === 'object' ? (options[(value as string | number)] as ComplexOption).label : options[(value as string | number)] as string) ?? value : (value.length > (multiple.maxDisplayedItems ?? multipleMaxDisplayedItems) ? (value.length + " " + (multiple.overMaxDisplayedItemsLabel ?? multipleOverMaxDisplayedItemsLabel)) : ((value as any[]).map(v => ((typeof options[v] === 'object' ? (options[v] as ComplexOption).label : options[v] as string) ?? v)).join(", ")))) : placeholder}</span>
                    <i className="fi fi-bs-angle-down text-primary-3" />
                </button>
                <div ref={refDropdown} className="select__dropdown js-dropdown">
                    {search === true && <input type="text" placeholder="Rechercher..." className="select__search js-search" value={optionSearch} onChange={(e) => setOptionSearch(e.target.value)} />}
                    <div className="select__options js-options">
                        {multiple === false && nullable === true &&
                            <div className="select__options__button" data-value="" onClick={(e) => {
                                onChange("")
                                refDropdown.current.classList.toggle("-is-visible");
                            }}>
                                {placeholder}
                            </div>
                        }
                        {Object.entries(options).filter(([optionValue, optionLabel]) => (typeof optionLabel === 'object' ? optionLabel.label : optionLabel).toLowerCase().includes(optionSearch.toLowerCase())).map(([optionValue, optionLabel], indexOption) => {
                            const label = (typeof optionLabel === 'object' ? optionLabel.label : optionLabel)
                            const sub = (typeof optionLabel === 'object' ? optionLabel.sub : null)
                            return (
                                <React.Fragment key={"option-" + indexOption}>
                                    <div className="select__options__button d-flex justify-content-between" data-value={optionLabel} onClick={(e) => {
                                        if (multiple === false) {
                                            onChange(optionValue)
                                            refDropdown.current.classList.toggle("-is-visible");
                                            return
                                        }
                                        onChange(toggleArrayElement(value, optionValue))
                                    }}>
                                        {multiple === false ?
                                            <>
                                                <div>{label}</div>
                                                {sub && <div className="select-sub">{sub}</div>}
                                            </>
                                            :
                                            <>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-checkbox pointer-events-none">
                                                        <input type="checkbox" checked={value.includes(optionValue)} onChange={(e) => { }} />
                                                        <div className="form-checkbox__mark">
                                                            <div className="form-checkbox__icon icon-check" />
                                                        </div>
                                                    </div>
                                                    <span className="ml-10 js-target-title">{label}</span>
                                                </div>
                                                {sub && <div className="select-sub">{sub}</div>}
                                            </>
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}
import router from "@/../config/router.json"
import { object } from "zod"

export function isHomePage(pathname: string[]): boolean {

    if (!pathname) {
        return true
    }

    if (Array.isArray(pathname) === false) {
        return false
    }

    if (pathname.length === 0) {
        return true
    }

    return false
}

/*
export function isHolidayStayPage(pathname: string[]): boolean {
    if (Array.isArray(pathname) === false) {
        return false
    }

    const regex = new RegExp('^' + [router.HolidayStay.prefix, router.HolidayStay.itemPathname].filter(p => p).join('/') + '\/.+' + '$')

    return pathname.join('/').match(regex) !== null
}

export function isBafadPage(pathname: string[]): boolean {
    if (Array.isArray(pathname) === false) {
        return false
    }

    const regex = new RegExp('^' + [router.Bafad.prefix, router.Bafad.itemPathname].filter(p => p).join('/') + '\/.+' + '$')

    return pathname.join('/').match(regex) !== null
}

export function isEducationCoursePage(pathname: string[]): boolean {
    if (Array.isArray(pathname) === false) {
        return false
    }

    const regex = new RegExp('^' + [router.EducationCourse.prefix, router.EducationCourse.itemPathname].filter(p => p).join('/') + '\/.+' + '$')

    return pathname.join('/').match(regex) !== null
}

export function isJobPage(pathname: string[]): boolean {
    if (Array.isArray(pathname) === false) {
        return false
    }

    const regex = new RegExp('^' + [router.Job.prefix, router.Job.itemPathname].filter(p => p).join('/') + '\/.+' + '$')

    return pathname.join('/').match(regex) !== null
}

export function isJobSpontaneousApplicationPage(pathname: string[]): boolean {
    if (Array.isArray(pathname) === false) {
        return false
    }

    const regex = new RegExp('^' + [router.Job.prefix, router.Job.spontaneousApplication].filter(p => p).join('/') + '$')

    return pathname.join('/').match(regex) !== null
}

export function isPostPage(pathname: string[]): boolean {
    if (Array.isArray(pathname) === false) {
        return false
    }

    const regex = new RegExp('^' + [router.Post.prefix, router.Post.itemPathname].filter(p => p).join('/') + '\/.+' + '$')

    return pathname.join('/').match(regex) !== null
}

export function isPressArticlePage(pathname: string[]): boolean {
    if (Array.isArray(pathname) === false) {
        return false
    }

    const regex = new RegExp('^' + [router.PressArticle.prefix, router.PressArticle.itemPathname].filter(p => p).join('/') + '\/.+' + '$')

    return pathname.join('/').match(regex) !== null
}

export function isAccomodationPage(pathname: string[]): boolean {
    if (Array.isArray(pathname) === false) {
        return false
    }

    const regex = new RegExp('^' + [router.Accomodation.prefix, router.Accomodation.itemPathname].filter(p => p).join('/') + '\/.+' + '$')

    return pathname.join('/').match(regex) !== null
}

export function isSearchPage(pathname: string[]): boolean {
    
    if (Array.isArray(pathname) === false) {
        return false
    }    

    return [router.searchPagePathname, ...Object.entries(router).map(([sector, sectorConfig]: [string, any]) => [sectorConfig.prefix, sectorConfig.searchPagePathname].filter(p => p).join('/')).filter(r => r)].includes(pathname.join('/'))    
}

export function getSearchPageSector(pathname: string[]): string {
    
    if (isSearchPage(pathname) === false) {
        return null
    }

    return Object.entries(router).filter(([sector, sectorConfig]: [string, any]) => ['HolidayStay', 'Bafad', 'EducationCourse', 'School', 'Job', 'Post', 'PressArticle', 'Publication'].includes(sector)).filter(([sector, sectorConfig]: [string, any]) => [sectorConfig.prefix, sectorConfig.searchPagePathname].filter(p => p).join('/') === pathname.join('/')).map(([sector, sectorPathname]) => sector)[0]
}

export function createHolidayStayPathname(holidayStay: HolidayStay): string {    

    return '/' + [router.HolidayStay.prefix, router.HolidayStay.itemPathname, [holidayStay.slug, holidayStay.id].join('-')].filter(p => p).join('/')
}

export function createBafadPathname(bafad: Bafad): string {

    return '/' + [router.Bafad.prefix, router.Bafad.itemPathname, [bafad.slug, bafad.id].join('-')].filter(p => p).join('/')
}

export function createEducationCoursePathname(educationCourse: EducationCourse): string {    
    
    return '/' + [router.EducationCourse.prefix, router.EducationCourse.itemPathname, [educationCourse.slug, educationCourse.id].join('-')].filter(p => p).join('/')
}

export function createSchoolActivityPathname(schoolActivity: SchoolActivity): string {
    
    return '/' + [router.School.prefix, router.School.activityItemPathname, schoolActivity.id].filter(p => p).join('/')
}

export function createAccomodationPathname(accomodation: Accomodation): string {   
    
    return '/' + [router.Accomodation.prefix, router.Accomodation.itemPathname, accomodation.id].filter(p => p).join('/')
}

export function createJobPathname(job: Job): string {   
    
    return '/' + [router.Job.prefix, router.Job.itemPathname, job.id].filter(p => p).join('/')
}

export function createPostPathname(post: Post): string {   
    
    return '/' + [router.Post.prefix, router.Post.itemPathname, post.id].filter(p => p).join('/')
}

export function createPressArticlePathname(pressArticle: PressArticle): string {   
    
    return '/' + [router.PressArticle.prefix, router.PressArticle.itemPathname, pressArticle.id].filter(p => p).join('/')
}*/

export function getAsServerMediaUrl(url: string) {
    return process.env.NEXT_PUBLIC_AS_SERVER_APP_URL + url
}

export function getMediaUrl(url: string) {
    return process.env.NEXT_PUBLIC_APP_URL + url
}

export function createSearchPagePathname(type: string, target: string, searchParams?: Record<string, string>) {
    if (type === 'sector') {
        if (target in router) {
            if (router[target].search !== null) {
                return '/' + [
                    router[target].root,
                    router[target].search as string
                ].filter(p => p).join('/') + (searchParams ? '?' + (new URLSearchParams(searchParams)).toString() : '')
            }
        }
    }
    if (type === 'object') {
        for (const [keySector, sector] of Object.entries(router)) {
            if (target in sector.objects) {
                return '/' + [
                    sector.root,
                    sector.objects[target].search as string
                ].filter(p => p).join('/') + (searchParams ? '?' + (new URLSearchParams(searchParams)).toString() : '')
            }
        }
    }

    return '/'
}

export function createObjectPagePathname(type: string, object: Searchable, searchParams?: Record<string, string>): string {
    for (const [keySector, sector] of Object.entries(router)) {
        if (type in sector.objects) {
            return '/' + [
                sector.root,
                (sector.objects[type].prefix as string),
                (sector.objects[type].item as string).replaceAll('{id}', object?.id).replaceAll('{slug}', object && 'slug' in object ? object?.slug : object?.id)
            ].filter(p => p).join('/') + (searchParams ? '?' + (new URLSearchParams(searchParams)).toString() : '')
        }
    }    
    return '/'
}

export function createStaticPagePathname(sector: string, type: string, searchParams?: Record<string, string>) {
    let pathname = '/'

    if (sector in router) {
        if (type in router[sector].static) {
            pathname = '/' + [
                router[sector].root,
                router[sector].static[type].pathname as string
            ].filter(p => p).join('/')
        }
    }

    return pathname + (searchParams ? '?' + (new URLSearchParams(searchParams)).toString() : '')
}

export function isObjectPage(type: string, pathname: string[]): boolean {

    if (!pathname) {
        return false
    }
    
    let regexBody = []
    Object.entries(router).map(([keySector, sector]) => {
        if (type in sector.objects) {
            regexBody = [
                sector.root,
                (sector.objects[type].prefix as string),
                (sector.objects[type].item as string).replaceAll('{id}', '.+').replaceAll('{slug}', '.+')
            ]
        }
    })

    const regex = new RegExp('^' + regexBody.filter(p => p).join('/') + '$')

    return pathname.join('/').match(regex) !== null
}

export function getObjectPageAction(pathname: string[]): {type: string, action: string} {
    if (!pathname) {
        return null
    }

    let actionPages = {}

    Object.entries(router).map(([sector, sectorConfig]) => {
        Object.entries(sectorConfig.objects).map(([keyObject, object]) => {            
            Object.entries(object.actions).map(([keyAction, action]) => {   
                actionPages[[sectorConfig.root, object.prefix, object.item.replaceAll('{id}', '.+').replaceAll('{slug}', '.+'), action].filter(p => p).join('/')] = {
                    type: keyObject,
                    action: keyAction                    
                }
            })            
        })
    })

    for (const regexBody in actionPages) {
        const regex = new RegExp('^' + regexBody + '$')
        if (pathname.join('/').match(regex) !== null) {
            return actionPages[regexBody]
        }
        
    }

    return null       
}

export function createObjectActionPagePathname(type: string, object: Searchable, action: string, searchParams?: Record<string, string>) {
    let actionPathname = ''
    
    Object.entries(router).map(([sector, sectorConfig]) => {
        Object.entries(sectorConfig.objects).map(([keyObject, object]) => {            
            if (keyObject === type) {
                Object.entries(object.actions).map(([keyAction, pathname]) => {            
                    if (keyAction === action) {
                        actionPathname = pathname as string
                    }
                })
            }
        })
    })
    
    return createObjectPagePathname(type, object) + '/' + actionPathname + (searchParams ? '?' + (new URLSearchParams(searchParams)).toString() : '');
}

export function isSearchPage(pathname: string[]) {
    if (!pathname) {
        return false
    }

    return [
        'recherche',
        ...Object.entries(router).reduce((pathnames, [sector, sectorConfig]) => 
            [...pathnames, 
            ...(sectorConfig.search !== null ? [[sectorConfig.root, sectorConfig.search].filter(p => p).join('/')] : []),
            ...Object.entries(sectorConfig.objects).reduce((objectsPathnames, [object, objectConfig]) => 
                [...objectsPathnames,
                ...(objectConfig.search !== null ? [[sectorConfig.root, objectConfig.search].filter(p => p).join('/')] : [])
                ], [])], []).filter(r => r)
    ].includes(pathname.join('/'))    
}

export function getSearchPageTarget(pathname: string[]): {sector: string, object: string} {

    if (!pathname) {
        return null
    }

    let searchPages = {}

    Object.entries(router).map(([sector, sectorConfig]) => {
        if (sectorConfig.search !== null) {
            searchPages[[sectorConfig.root, sectorConfig.search].filter(p => p).join('/')] = {
                sector,
                object: null
            }
        }
        Object.entries(sectorConfig.objects).map(([object, objectConfig]) => {
            if (objectConfig.search !== null) {
                searchPages[[sectorConfig.root, objectConfig.search].filter(p => p).join('/')] = {
                    sector,
                    object
                }
            }
        })
    })

    if (Object.keys(searchPages).includes(pathname.join('/'))) {
        return searchPages[pathname.join('/')]
    }

    return null    
}

export function isStaticPage(pathname: string[]): boolean {
    return getStaticPageTarget(pathname) !== null    
}

export function getStaticPageTarget(pathname: string[]): {sector: string, staticPage: string} {
    if (!pathname) {
        return null
    }

    let staticPages = {}

    Object.entries(router).map(([sector, sectorConfig]) => {
        Object.entries(sectorConfig.static).map(([staticPage, staticPageConfig]) => {            
            staticPages[[sectorConfig.root, staticPageConfig.pathname].filter(p => p).join('/')] = {
                sector,
                staticPage
            }            
        })
    })

    if (Object.keys(staticPages).includes(pathname.join('/'))) {
        return staticPages[pathname.join('/')]
    }

    return null       
}

export function getObjectIdentifier(type: string, pathname: string[]): string {
    let pathnameDefinition = []
    let identifier = [...pathname]

    Object.entries(router).map(([keySector, sector]) => {
        if (type in sector.objects) {
            pathnameDefinition = [
                sector.root,
                (sector.objects[type].prefix as string),
                (sector.objects[type].item as string)
            ]
        }
    })

    pathnameDefinition.map((segment, indexSegment) => {
        if (indexSegment !== 2) { // position of item definition
            if (indexSegment < 2) {
                identifier = identifier.slice(segment.split('/').length)
            }
            if (indexSegment > 2) {
                identifier = identifier.slice(1, segment.split('/').length)
            }            
        }        
    })

    return identifier.length > 0 ? identifier[0] : ''
}